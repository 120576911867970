var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import styled from '@emotion/styled';
import { height, minHeight, minWidth, space, width } from 'styled-system';
import { StateType } from '../../../enums/StateType';
import { fill } from '../../../theme/system-utilities';
import Icon from '../../Icon';
import { ThemeColor } from './enums';
var DEFAULT_SIZE = 24;
var StyledIcon = styled(Icon)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"])), space, width, height, minWidth, minHeight, fill);
var getColorValue = function (type) {
    switch (type) {
        case StateType.Error:
            return ThemeColor.Error;
        case StateType.Warning:
            return ThemeColor.Warning;
        case StateType.Success:
            return ThemeColor.Success;
        case StateType.Loading:
            return ThemeColor.Active;
    }
};
var getIconStyleProps = function (type) {
    return { fill: getColorValue(type) };
};
var StateIcon = function (_a) {
    var type = _a.type, _b = _a.size, size = _b === void 0 ? DEFAULT_SIZE : _b, className = _a.className, spaceProps = __rest(_a, ["type", "size", "className"]);
    var sizeProps = {
        width: size,
        height: size,
        minWidth: size,
        minHeight: size,
    };
    return (React.createElement(StyledIcon, __assign({ name: type, className: className }, sizeProps, spaceProps, getIconStyleProps(type))));
};
export default StateIcon;
var templateObject_1;
