import { HTMLInputFieldType } from '../../../enums/form';
import { StateType } from '../../../enums/StateType';
import { ThemeColor } from './enums';
export var getStateType = function (_a) {
    var hasError = _a.hasError, hasWarning = _a.hasWarning, hasSuccess = _a.hasSuccess, isLoading = _a.isLoading;
    switch (true) {
        case hasError:
            return StateType.Error;
        case hasWarning:
            return StateType.Warning;
        case hasSuccess:
            return StateType.Success;
        case isLoading:
            return StateType.Loading;
        default:
            return StateType.Default;
    }
};
export var getStateColor = function (type, value) {
    switch (type) {
        case StateType.Error:
            return ThemeColor.Error;
        case StateType.Warning:
            return ThemeColor.Warning;
        case StateType.Success:
            return ThemeColor.Success;
        case StateType.Default:
            return value ? ThemeColor.Active : ThemeColor.Default;
        default:
            return ThemeColor.Active;
    }
};
export var getThemeColorKey = function (state, value) {
    return "colors.".concat(getStateColor(state, value));
};
export var isTel = function (type) { return type === HTMLInputFieldType.Tel; };
