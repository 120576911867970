var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { border, borderColor, borderRadius, borderStyle, borderWidth, fontSize, space, themeGet, width, zIndex, } from 'styled-system';
import { StateType } from '../../../enums/StateType';
import { getForwardPropOpts } from '../../../theme/helpers/forward-props';
import { boxSizing } from '../../../theme/system-utilities';
import { TRANSITION_CSS } from './constants';
import { ThemeColor } from './enums';
import { getStateType, getThemeColorKey } from './utils';
var ICON_SPACING_RIGHT = '30px';
export var applyInputCSS = function (props) {
    var hasError = props.hasError, hasWarning = props.hasWarning, hasSuccess = props.hasSuccess, isLoading = props.isLoading, withIcon = props.withIcon;
    var stateType = getStateType({
        hasError: hasError,
        hasWarning: hasWarning,
        hasSuccess: hasSuccess,
        isLoading: isLoading,
    });
    return (stateType !== StateType.Default &&
        withIcon && css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n      padding-right: ", ";\n    "], ["\n      padding-right: ", ";\n    "])), ICON_SPACING_RIGHT));
};
export var inputFocusCSS = function (props) { return css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  border-color: transparent;\n  outline: 2px solid ", ";\n"], ["\n  border-color: transparent;\n  outline: 2px solid ", ";\n"])), themeGet('colors.sys.secondary.border.focus')(props)); };
export var inputDisabledCSS = function (props) { return css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  color: ", ";\n  background-color: ", ";\n  border-color: ", ";\n"], ["\n  color: ", ";\n  background-color: ", ";\n  border-color: ", ";\n"])), themeGet("colors.".concat(ThemeColor.Placeholder))(props), themeGet('colors.sys.neutral.background.hover')(props), themeGet("colors.".concat(ThemeColor.Default))(props)); };
export var applyInputWithStateCSS = function (props) {
    var hasError = props.hasError, hasWarning = props.hasWarning, hasSuccess = props.hasSuccess, isLoading = props.isLoading, value = props.value;
    var stateType = getStateType({
        hasError: hasError,
        hasWarning: hasWarning,
        hasSuccess: hasSuccess,
        isLoading: isLoading,
    });
    var themeColor = getThemeColorKey(stateType, value);
    return css(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    border-color: ", ";\n    color: ", ";\n  "], ["\n    border-color: ", ";\n    color: ", ";\n  "])), themeGet(themeColor)(props), themeGet(themeColor)(props));
};
var InnerInput = styled('input', getForwardPropOpts([]))(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", "\n  ", ";\n\n  transition: ", ";\n  transition:\n    outline,\n    color 0s;\n\n  &::placeholder {\n    transition: ", ";\n    color: transparent;\n  }\n\n  &:focus::placeholder {\n    color: ", ";\n  }\n\n  &:not(:disabled) {\n    &:active,\n    &:focus {\n      ", ";\n    }\n  }\n\n  &:disabled {\n    ", ";\n  }\n\n  &[aria-invalid='true'] {\n    padding-right: ", "px;\n  }\n\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", "\n  ", ";\n\n  transition: ", ";\n  transition:\n    outline,\n    color 0s;\n\n  &::placeholder {\n    transition: ", ";\n    color: transparent;\n  }\n\n  &:focus::placeholder {\n    color: ", ";\n  }\n\n  &:not(:disabled) {\n    &:active,\n    &:focus {\n      ", ";\n    }\n  }\n\n  &:disabled {\n    ", ";\n  }\n\n  &[aria-invalid='true'] {\n    padding-right: ", "px;\n  }\n\n  ", ";\n  ", ";\n"])), width, fontSize, space, boxSizing, borderRadius, borderStyle, borderWidth, borderColor, border, zIndex, TRANSITION_CSS, TRANSITION_CSS, themeGet("colors.".concat(ThemeColor.Placeholder)), inputFocusCSS, inputDisabledCSS, themeGet('space.sp_32'), applyInputCSS, applyInputWithStateCSS);
InnerInput.defaultProps = {
    width: 1,
    fontSize: ['fs_16', null, null, 'fs_18'],
    mt: 'sp_0',
    mb: ['sp_16', null, 'sp_0'],
    px: 'sp_16',
    py: 'sp_8',
    boxSizing: 'border-box',
    borderStyle: 'solid',
    borderWidth: 'small',
    borderColor: 'sys.neutral.border.inactive',
    borderRadius: 'small',
    zIndex: 0,
};
export default InnerInput;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
