var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useMemo } from 'react';
import styled from '@emotion/styled';
import uniqueId from 'lodash/uniqueId';
import { overflow, position, width } from 'styled-system';
import { StateType } from '../../../enums/StateType';
import InnerInput from './InnerInput';
import Label, { applyActiveLabelCSS, applyLabelCSS, labelActiveCSS, labelFocusCSS } from './Label';
import StateMessages from './StateMessages';
var EMPTY_LIST = [];
export var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n\n  > label:not(.label-wrapper) {\n    ", ";\n    ", ";\n  }\n\n  &:focus-within label:not(.label-wrapper) {\n    ", ";\n    ", ";\n  }\n"], ["\n  ", ";\n  ", ";\n  ", ";\n\n  > label:not(.label-wrapper) {\n    ", ";\n    ", ";\n  }\n\n  &:focus-within label:not(.label-wrapper) {\n    ", ";\n    ", ";\n  }\n"])), position, width, overflow, applyLabelCSS, applyActiveLabelCSS, labelActiveCSS, labelFocusCSS);
var wrapperStyles = {
    position: 'relative',
    width: 1,
};
var Input = function (_a) {
    var label = _a.label, inputRef = _a.inputRef, _b = _a.errors, errors = _b === void 0 ? EMPTY_LIST : _b, _c = _a.warnings, warnings = _c === void 0 ? EMPTY_LIST : _c, _d = _a.successes, successes = _d === void 0 ? EMPTY_LIST : _d, _e = _a.messages, messages = _e === void 0 ? EMPTY_LIST : _e, withIcon = _a.withIcon, withMessagesIcon = _a.withMessagesIcon, onChange = _a.onChange, labelStyles = _a.labelStyles, restProps = __rest(_a, ["label", "inputRef", "errors", "warnings", "successes", "messages", "withIcon", "withMessagesIcon", "onChange", "labelStyles"]);
    var initialId = restProps.id, type = restProps.type, value = restProps.value, disabled = restProps.disabled, hasError = restProps.hasError, hasWarning = restProps.hasWarning, hasSuccess = restProps.hasSuccess, isLoading = restProps.isLoading, inputWidth = restProps.width;
    var id = useMemo(function () { return initialId || uniqueId('input-'); }, []);
    var wrapperProps = __assign(__assign(__assign({}, wrapperStyles), { type: type, value: value, disabled: disabled, hasError: hasError, hasWarning: hasWarning, hasSuccess: hasSuccess, isLoading: isLoading }), (inputWidth && { width: inputWidth }));
    return (React.createElement(Wrapper, __assign({}, wrapperProps, { "data-testid": "input-wrapper" }),
        React.createElement(InnerInput, __assign({ id: id, ref: inputRef, onChange: onChange, value: value }, (hasError && {
            'aria-invalid': true,
            'aria-describedby': "".concat(id, "-").concat(StateType.Error, "-0"),
        }), { disabled: disabled }, restProps)),
        label && (React.createElement(Label, __assign({ htmlFor: id }, labelStyles), label)),
        React.createElement(StateMessages, { id: id, withIcon: withIcon, withMessagesIcon: withMessagesIcon, errors: errors, warnings: warnings, successes: successes, messages: messages, hasError: hasError, hasWarning: hasWarning, hasSuccess: hasSuccess, isLoading: isLoading })));
};
export default Input;
var templateObject_1;
