export var CheckboxValue;
(function (CheckboxValue) {
    CheckboxValue["True"] = "true";
    CheckboxValue["False"] = "false";
})(CheckboxValue || (CheckboxValue = {}));
export var CheckboxAnalyticsValue;
(function (CheckboxAnalyticsValue) {
    CheckboxAnalyticsValue["Select"] = "Select";
    CheckboxAnalyticsValue["Deselect"] = "Deselect";
})(CheckboxAnalyticsValue || (CheckboxAnalyticsValue = {}));
export var ButtonValue;
(function (ButtonValue) {
    ButtonValue["Clicked"] = "clicked";
    ButtonValue["Off"] = "off";
})(ButtonValue || (ButtonValue = {}));
export var TitlePrefix;
(function (TitlePrefix) {
    TitlePrefix["Mrs"] = "mrs";
    TitlePrefix["Mr"] = "mr";
    TitlePrefix["Diverse"] = "diverse";
})(TitlePrefix || (TitlePrefix = {}));
export var HTMLInputFieldType;
(function (HTMLInputFieldType) {
    HTMLInputFieldType["Text"] = "text";
    HTMLInputFieldType["Tel"] = "tel";
    HTMLInputFieldType["Password"] = "password";
})(HTMLInputFieldType || (HTMLInputFieldType = {}));
export var HTMLAutoCompleteAttribute;
(function (HTMLAutoCompleteAttribute) {
    HTMLAutoCompleteAttribute["Off"] = "off";
    HTMLAutoCompleteAttribute["CurrentPassword"] = "current-password";
    HTMLAutoCompleteAttribute["NewPassword"] = "new-password";
    HTMLAutoCompleteAttribute["Username"] = "username";
})(HTMLAutoCompleteAttribute || (HTMLAutoCompleteAttribute = {}));
export var FieldChangeTrigger;
(function (FieldChangeTrigger) {
    FieldChangeTrigger["Change"] = "change";
    FieldChangeTrigger["Blur"] = "blur";
})(FieldChangeTrigger || (FieldChangeTrigger = {}));
