var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { borderRadius, color, display, fontSize, lineHeight, maxWidth, overflow, position, space, themeGet, zIndex, } from 'styled-system';
import { StateType } from '../../../enums/StateType';
import { pointerEvents, themeLeft, themeRight, themeTop, whiteSpace, } from '../../../theme/system-utilities';
import { TRANSITION_CSS } from './constants';
import { ThemeColor } from './enums';
import { getStateType, getThemeColorKey, isTel } from './utils';
var TEL_LEFT_SPACING = '90px';
var ICON_RIGHT_SPACING = '34px';
var Label = styled.label(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  user-select: none;\n  transition: ", ";\n"], ["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  user-select: none;\n  transition: ", ";\n"])), display, position, themeTop, themeLeft, themeRight, maxWidth, color, fontSize, lineHeight, space, zIndex, overflow, whiteSpace, pointerEvents, borderRadius, TRANSITION_CSS);
Label.defaultProps = {
    display: 'inline-block',
    position: 'absolute',
    themeTop: 0,
    themeLeft: 0,
    themeRight: ['sp_16', null, null, 'sp_20'],
    maxWidth: 'fit-content',
    color: 'transparent',
    fontSize: ['fs_16', null, null, 'fs_18'],
    lineHeight: [1, null, null, '18px'],
    ml: ['sp_12', null, null, 'sp_16'],
    my: '10px',
    px: 'sp_2',
    zIndex: 1,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    pointerEvents: 'none',
    borderRadius: 'small',
};
export var labelActiveCSS = function (props) {
    var hasError = props.hasError, hasWarning = props.hasWarning, hasSuccess = props.hasSuccess, isLoading = props.isLoading, value = props.value;
    var stateType = getStateType({
        hasError: hasError,
        hasWarning: hasWarning,
        hasSuccess: hasSuccess,
        isLoading: isLoading,
    });
    var themeColor = getThemeColorKey(stateType, value);
    return css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    width: auto;\n    left: 0;\n    transform: translate(0, -18px);\n    color: ", ";\n    background-color: ", ";\n    font-size: ", "px;\n    margin-bottom: 0;\n    text-overflow: ellipsis;\n  "], ["\n    width: auto;\n    left: 0;\n    transform: translate(0, -18px);\n    color: ", ";\n    background-color: ", ";\n    font-size: ", "px;\n    margin-bottom: 0;\n    text-overflow: ellipsis;\n  "])), themeGet(themeColor)(props), themeGet('colors.sys.neutral.background.default')(props), themeGet('fontSizes.fs_14')(props));
};
export var labelFocusCSS = function (props) { return css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  color: ", ";\n"], ["\n  color: ", ";\n"])), themeGet("colors.".concat(ThemeColor.Focus))(props)); };
export var applyLabelCSS = function (props) {
    var type = props.type, hasError = props.hasError, hasWarning = props.hasWarning, hasSuccess = props.hasSuccess, withIcon = props.withIcon, isLoading = props.isLoading, value = props.value;
    var stateType = getStateType({
        hasError: hasError,
        hasWarning: hasWarning,
        hasSuccess: hasSuccess,
        isLoading: isLoading,
    });
    var placeholderColorStyle = css(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    color: ", ";\n  "], ["\n    color: ", ";\n  "])), themeGet("colors.".concat(ThemeColor.Placeholder))(props));
    return css(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n    ", "\n    ", "\n    ", "\n  "], ["\n    ", "\n    ", "\n    ", "\n  "])), !isTel(type) &&
        stateType !== StateType.Default &&
        withIcon && css(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n      right: ", ";\n    "], ["\n      right: ", ";\n    "])), ICON_RIGHT_SPACING), isTel(type) && css(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n      left: ", ";\n    "], ["\n      left: ", ";\n    "])), TEL_LEFT_SPACING), !value && placeholderColorStyle);
};
export var applyActiveLabelCSS = function (props) {
    var disabled = props.disabled;
    var labelActiveStyle = css(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n    ", ";\n  "], ["\n    ", ";\n  "])), labelActiveCSS(props));
    return css(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n    ", "\n  "], ["\n    ", "\n  "])), !disabled && !!props.value && labelActiveStyle);
};
export default Label;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9;
