export var ThemeColor;
(function (ThemeColor) {
    ThemeColor["Default"] = "sys.neutral.text.inactive";
    ThemeColor["Active"] = "sys.neutral.text.active";
    ThemeColor["Placeholder"] = "sys.neutral.text.inactive";
    ThemeColor["Focus"] = "sys.secondary.background.hover";
    ThemeColor["Error"] = "sys.error.text.default";
    ThemeColor["Warning"] = "sys.warning.background.default";
    ThemeColor["Success"] = "sys.success.background.default";
})(ThemeColor || (ThemeColor = {}));
