var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from '@emotion/styled';
import { display } from 'styled-system';
import { Flex } from '../../Flex';
import { CaptionRegularCR1 } from '../../Typography';
import StateIcon from './StateIcon';
import { getStateColor } from './utils';
var Message = styled(CaptionRegularCR1)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", ";\n"], ["\n  ", ";\n"])), display);
var StateMessage = function (_a) {
    var id = _a.id, type = _a.type, withIcon = _a.withIcon, children = _a.children;
    return (React.createElement(Flex, { as: "li", alignItems: "center", mb: withIcon ? 'sp_0' : 'sp_4', ml: withIcon ? 'sp_0' : 'sp_16', role: "alert" },
        withIcon && React.createElement(StateIcon, { mr: "sp_4", type: type }),
        React.createElement(Message, { id: id, as: "span", display: "block", color: getStateColor(withIcon ? null : type) }, children)));
};
export default StateMessage;
var templateObject_1;
