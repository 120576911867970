var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useMemo } from 'react';
import styled from '@emotion/styled';
import { position, space } from 'styled-system';
import { StateType } from '../../../enums/StateType';
import { pointerEvents, themeRight, themeTop } from '../../../theme/system-utilities';
import { Flex } from '../../Flex';
import StateIcon from './StateIcon';
import StateMessage from './StateMessage';
import { getStateType } from './utils';
var ICON_SIZE = 32;
var List = styled(Flex)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", ";\n"], ["\n  ", ";\n"])), space);
List.defaultProps = {
    as: 'ul',
    flexDirection: 'column',
    m: 'sp_0',
    mt: 'sp_4',
    p: 'sp_0',
};
var StyledStateIcon = styled(StateIcon)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"])), position, themeTop, themeRight, pointerEvents);
StyledStateIcon.defaultProps = {
    position: 'absolute',
    themeTop: 'sp_2',
    themeRight: 'sp_4',
    pointerEvents: 'none',
    size: ICON_SIZE,
};
var useMessagesWithType = function (_a) {
    var errors = _a.errors, warnings = _a.warnings, successes = _a.successes, messages = _a.messages;
    return useMemo(function () {
        return messages.length > 0
            ? messages
            : __spreadArray(__spreadArray(__spreadArray([], __read(errors.map(function (message) { return ({ message: message, type: StateType.Error }); })), false), __read(warnings.map(function (message) { return ({ message: message, type: StateType.Warning }); })), false), __read(successes.map(function (message) { return ({ message: message, type: StateType.Success }); })), false);
    }, [errors, warnings, successes, messages]);
};
var StateMessages = function (_a) {
    var id = _a.id, _b = _a.withMessagesIcon, withMessagesIcon = _b === void 0 ? true : _b, _c = _a.withIcon, withIcon = _c === void 0 ? false : _c, _d = _a.hasError, hasError = _d === void 0 ? false : _d, _e = _a.hasWarning, hasWarning = _e === void 0 ? false : _e, _f = _a.hasSuccess, hasSuccess = _f === void 0 ? false : _f, _g = _a.isLoading, isLoading = _g === void 0 ? false : _g, _h = _a.errors, errors = _h === void 0 ? [] : _h, _j = _a.warnings, warnings = _j === void 0 ? [] : _j, _k = _a.successes, successes = _k === void 0 ? [] : _k, _l = _a.messages, messages = _l === void 0 ? [] : _l;
    var stateMessages = useMessagesWithType({
        errors: errors,
        warnings: warnings,
        successes: successes,
        messages: messages,
    });
    var isStateApplied = !withIcon && (hasError || hasWarning || hasSuccess || isLoading);
    return (React.createElement(React.Fragment, null,
        isStateApplied && (React.createElement(StyledStateIcon, { type: getStateType({ hasError: hasError, hasWarning: hasWarning, hasSuccess: hasSuccess, isLoading: isLoading }) })),
        stateMessages.length > 0 && (React.createElement(List, null, stateMessages.map(function (_a, index) {
            var message = _a.message, type = _a.type;
            return (React.createElement(StateMessage, { id: "".concat(id, "-").concat(type, "-").concat(index), key: "".concat(type, " ").concat(message), type: type, withIcon: withMessagesIcon && withIcon }, message));
        })))));
};
export default StateMessages;
var templateObject_1, templateObject_2;
